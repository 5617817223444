// src/AboutAdintors.js

import React from "react";
import { Link } from "react-router-dom";
// import "./styles.css";

const AboutAdintors = () => {
  return (
    <section className="about-adintors">
      <div className="about-us">
        <p className="about-titles">ABOUT US</p>
        <p className="about-desc">
        The name 'Adintors' is an amalgamation of advertisements, influencers, and creators.
        </p>
        <p className="about-desc">
        We are coming up with an exciting new application called Adintors that will revolutionise the way brands and businesses manage their advertisements and promotional activities.
        </p>
        <p className="about-desc">
        Adintors is an innovative platform that connects Brands and Businesses to Influencers and Creators for effective brand promotion and marketing. The platform is designed to meet the needs of both Businesses and Creators. For businesses, we offer a wide range of features to help them identify and collaborate with the right content creator. For creators, we provide a streamlined way to find new brands to partner with and monetize their content.
        </p>
        <p className="about-desc">
        Our application connects brands and businesses with a diverse pool of social media content creators who can help promote their products or services to a wider audience. It is a platform where businesses can find the perfect social media creator for their specific marketing requirements within their specific budget.
        </p>
        <p className="about-desc">
        The application is intuitive, making it easy for businesses to navigate and contact the right influencers for their campaigns. It provides detailed analytics and reporting, allowing users to track their campaign's performance.
        </p>
        <p className="about-desc">
        Adintors is designed to make it super easy for businesses to find and connect with creators and influencers who match their target audience, marketing goals, and most importantly their budget. We understand that every business is unique, therefore, we provide a user-friendly application that simplifies the process of finding the right influencers for their brand and streamlines the entire collaboration process all based on our excellent database.
        </p>
        <div className="btn-about">
        <Link to="/about-us">
          <button className="button">
              <span className="clickd">Explore more</span>
              <img src="./images/about/right-arrow.svg" alt="arrow" />
          </button>
      </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutAdintors;
