import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormTwo from '../component/contact/FormTwo';


const Contact = () => {
    return (
        <>
            <SEO title="Blog Grid" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Contact"
                page="Contact"
                />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 pt-6">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">Contact us</h3>
                                <FormTwo />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 offset-xl-1 mt-5">

                            <div className='row'>
                                <div className='col-lg-6'>
                                <div className="contact-info mb--30 mt_md--0 ">
                                <h4 className="title">Phone</h4>
                                <p> Mon-Fri, &nbsp; 9:00 am to 6:00 pm</p>
                                <h5 className="phone-number"><a href="tel:1234567890">(079) 4917 6631</a></h5>
                            </div>
                                </div>
                                <div className='col-lg-6'>
                                <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <p>Our support team will get back to in 48-hours during standard business hours.</p>
                                <h5 className="phone-number"><a href="mailto:info@adintors.com">info@adintors.com</a></h5>
                            </div>
                                </div>
                            </div>
                           
                           
                            <div className="contact-info mb--30">
                                <h4 className="title">Address</h4>
                                <p>Our office  is open from Mon-Fri, 9:00 am to 6:00 pm.</p>
                                <h5 className="phone-number"><a href="https://www.google.com/maps/place/Adintors/@23.0339257,72.5021766,17z/data=!3m1!4b1!4m6!3m5!1s0x395e9b3b8db55f1d:0x66c6655a05586518!8m2!3d23.0339208!4d72.5047515!16s%2Fg%2F11ssr9vhn2?entry=ttu">B-702, Shilp Corporate Park, Rajpath Rangoli Road, Bodakdev, Ahmedabad, Gujarat 380054.</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    {/* <li className="shape shape-1"><img src={ "/images/others/bubble-2.png"} alt="Bubble" /></li> */}
                    <li className="shape shape-2"><img src={ "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={ "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>

           
            <FooterOne parentClass=" pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;