import React from 'react'
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormOne from '../component/contact/FormOne';
import FooterOne from '../common/footer/FooterOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';


const Creators = () => {
    return (
        <>
              <ColorSwitcher />
                <HeaderOne />
                {/* <HeaderOne /> */}
                <BreadCrumbOne 
                title="Creator"
                page="Creator"
                />

        <FooterOne /> 
        </>
        

        
    )
}

export default Creators;