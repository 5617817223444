import React from 'react';
import { Link } from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';


import Tilty from 'react-tilty';


const BannerThree = () => {
    return (
            <div className="banner banner-style-3">
                {/* <div className='hero-img'>
                    <img className='hero-main-image' src={ "/images/banner/web-design-one.svg"}/> 
                    <img className='hero-main-image' src={ "/images/banner/hero-hero.png"}/> 
                </div> */}
                        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                <marquee behavior="scroll" direction="left" style={{ width: '100%' }}>
                                {/* Add your image inside the marquee */}
                                {/* <img src="" alt="Marquee Image" style={{ width: '100px', height: '100px' }} /> */}
                                {/* Repeat the image as many times as needed */}
                                <img src={ "/images/banner/Scoller-home.png"} />
        
                                </marquee>
                            </div>
            <div className="container" >
                {/* <div className="row align-items-center"> */}
                    {/* <div className="col-lg-6"> */}
                                    {/* <div className="banner-content">
                                        <AnimationOnScroll  animateIn="slideInUp" duration={1} animateOnce={true} delay={100}>
                                            <span className="subtitle">JOHNATHAN SMITH</span>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll  animateIn="slideInUp" duration={1} animateOnce={true} delay={200}>
                                            <h1 className="title">UI/UX ineraction designer</h1>
                                        </AnimationOnScroll>
                                        <AnimationOnScroll  animateIn="slideInUp" duration={1} animateOnce={true} delay={200}>
                                        <div className="btn-group">
                                            <a href="https://dribbble.com/axilweb/" className="axil-btn btn-fill-primary btn-large">Latest Work On Dribbble</a>
                                            <Link to={ "/about-us"} className="about-btn">About Me</Link>
                                        </div>
                                        </AnimationOnScroll>
                                    </div> */}
                    

                    {/* </div> */}
                {/* <div className="col-lg-6"> */}
                    {/* <div className="banner-thumbnail"> */}
                    {/* <div className="large-thumb">
                        <AnimationOnScroll  animateIn="slideInUp" duration={1} animateOnce={true} delay={200}>
                            <Tilty perspective={2000} reset={false}>
                                <img src={ "/images/banner/web-design-one.svg"} alt="Shape" />
                            </Tilty>
                        </AnimationOnScroll>
                    </div> */}
                    {/* </div> */}
                {/* </div> */}
                {/* </div> */}
            </div>
            <div className="banner-content">
                                    {/* <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}> */}
                                        <h1 className="title" style={{
                                            color:'#32475C'
                                        }}> Let’s Evolve with  the <br /> Modern Era of Marketing.</h1>
                                        {/* <span className="subtitle">Passion Dedication Innovation.</span> */}
                                        <Link to={ "/OurProduct"} className="axil-btn btn-fill-primary btn-large">Our Product</Link>
                                    {/* </AnimationOnScroll> */}
                                </div>
            <ul className="list-unstyled shape-group-20">
                {/* <li className="shape shape-1">
                <img src={ "/images/others/bubble-36.png"} alt="Bubble" />
                </li> */}
                {/* <li className="shape shape-2">
                <img src={ "/images/others/bubble-34.png"} alt="Bubble" />
                </li> */}
                <li className="shape shape-4">
                <img src={ "/images/others/bubble-14.png"} alt="Bubble" />
                </li>
                <li className="shape shape-5">
                <img src={ "/images/others/bubble-4.png"} alt="Bubble" />
                </li>
                <li className="shape shape-6">
                {/* <img src={ "/images/others/bubble-30.png"} alt="Bubble" /> */}
                </li>
                <li className="shape shape-7">
                <img src={ "/images/others/bubble-31.png"} alt="Bubble" />
                </li>
                <li className="shape shape-8 marque-images" />
            </ul>
        </div>

    )
}

export default BannerThree;
