import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
 import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { Link } from 'react-router-dom';




const PrivacyPolicy = () => {

    return (
        <>
            <>
                <SEO title="About Us" />
                <ColorSwitcher />
                <main className="main-wrapper">
                    <HeaderOne />


                    <div className='about-us-background'>
                        <div className="about-us-container">

                            <div className="breadcrum-About">

                                <div className="breadcrumb">
                                    <ul className="list-unstyled">
                                        <li>
                                            <Link to={"/"}>Home</Link>
                                        </li>
                                        <li className="active">PrivacyPolicy</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className='about-us-data'>
                            <p>Adintors Private Limited is a software development company. Our product Adintors is an application that is a platform that connects brands and businesses to social media creators and influencers. “Adintors”, “we”, “us”,  and “our” are all used for Adintors. </p>
                            <p>Welcome to Adintors' Privacy Policy. We understand that your privacy is important, and we are committed to protecting it. We take your privacy seriously and that's why we appreciate you reading our Privacy Policy. This Policy explains how we collect, use, share, and protect your personal information when you use our services, such as when you: </p>
                            <ul>
                                <li>Download and use our mobile application, or any other application or website of ours that links to this Privacy Policy. </li>
                                <li>Engage with us in other related ways, including any sales, marketing, or events. </li>
                            </ul>
                            <p>Adintors is an innovative platform that connects Businesses and Brands to Influencers and Creators for effective brand promotion and marketing. We are not a social media company, but a platform that bridges the gap between Businesses and Influencers to achieve successful brand promotion and marketing. Our company provides a user-friendly application that simplifies the process of finding the right influencers for your brand and streamlines the entire collaboration process. </p>
                            <h4>Information We Collect: </h4>
                            <p>When you sign up for our platform, we collect the following information:</p>
                            <h4>From Businesses: (only for the Indian region) </h4>
                            <h4>Proprietor/Individual: </h4>
                            <p>Entity Name, Country, and Email Address.  </p>
                            <p>We may collect the following details from your Instagram account if you choose to connect it:Instagram Business ID, Facebook ID. This information is utilized to access meta APIs for retrieving Engagement, Reach, Audience demographics (including Gender, Age with Gender, Country, and City), and Performance Metrics to enhance user experience and service quality.</p>
                            <h4>Partnership, LLP, Private Limited, Limited: </h4>
                            <p>Entity Name, Country, and Email Address. </p>
                            <p>We may collect the following details from your Instagram account if you choose to connect it:Instagram Business ID, Facebook ID. This information is utilized to access meta APIs for retrieving Engagement, Reach, Audience demographics (including Gender, Age with Gender, Country, and City), and performance metrics to enhance user experience and service quality.</p>
                            <h4>From Influencers: </h4>
                            <p>Full Name, Date of Birth and Email Address. </p>
                            <p>We may collect the following details from your Instagram account if you choose to connect it:Instagram Business ID, Facebook ID. This information is utilized to access meta APIs for retrieving Engagement, Reach, Audience demographics (including Gender, Age with Gender, Country, and City), and performance metrics to enhance user experience and service quality. </p>
                            <h4>From both Businesses and Influencers: </h4>
                            <p>We may also collect information about your use of our platform, such as cookies on our platform. </p>
                            <p>We use your personal information or social media insights to provide and improve our services, including:</p>
                            <ul>
                                <li>Facilitating communication between businesses and influencers</li>
                                <li>Processing payments (if applicable)</li>
                                <li>Analyzing performance metrics and providing insights</li>
                            </ul>
                            <p>We may also collect information about you from your previous social media campaigns. If you are an influencer, we may collect data about your Instagram (if connected), performance metrics such as engagement, and Detailed Demographic Statistics that help companies select the best influencers for their campaigns.</p>
                            <p>We may also use your personal information to communicate with you about our services and promotions. If you no longer wish to receive marketing emails from us, you can unsubscribe by clicking the "unsubscribe" link in the email. Also, if you no longer want to receive notifications, you can disable notifications from settings. </p>
                            <h4>Disclosure of Information</h4>
                            <p>If you're a brand, we may show your personal information to Influencers you are interested in working with on your profile page. If you're an Influencer, we may show your information to brands who might be interested in working with you on your profile page. </p>
                            <p>We may share your information with payment processors (if applicable). </p>
                            <p>We may also disclose your personal information if required by law or if we believe that disclosure is necessary to protect our rights or the rights of others.</p>
                            <p>Sensitive Information: We process/collect your sensitive information like gender based on the requirements of the orders. </p>
                            <p>Legal Data: Entities need to provide their PAN-Aadhar or GST Number to proceed further. Influencers need to provide their PAN-Aadhar-Enrolment ID or GST Number information to proceed further. </p>
                            <p>Application Data: When you use our application, we may also collect the following information if you choose to provide us with access or permission:</p>
                            <p>Push Notifications: We may request to send you push notifications regarding your account or certain features of our application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings, or the app’s settings. This information is primarily needed to maintain the security and operation of our application, for troubleshooting, and for our internal analytics and reporting purposes.</p>
                            <p>Online/Offline Notification: Our chat system has an online/offline feature. This means that if a user is active, it will be visible to their connected user/s that the user is online.</p>
                            <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
                            <h4>How We Use Your Information:</h4>
                            <p>We use your personal information to personalize our services to you, to communicate with you, to improve our services, and to provide you with the services you request, such as connecting you with influencers. We may also use your information to send you updates about the app, promotions, or other relevant information.</p>
                            <p>We use the data about influencers to provide Entities with the information they need to select the right influencers for their campaigns. We may also use this data to improve our application and services.</p>
                            <p>We may share your information in the following circumstances:</p>
                            <p>With Entities: We may share your information with entities looking for influencers for their campaigns.</p>
                            <p>With Service Providers: We may share your information with third-party service providers who help us to provide our services, such as payment processors. </p>
                            <p>With Legal Authorities: We may share your information when required by law or to protect our rights, property, or safety.</p>
                            <p>If you're an influencer, we use your personal information to analyze usage trends and improve our services. We use your personal information to provide you with our services, including connecting you with brands and managing your influencer campaigns. We may also use your information to send you marketing communications about our services and related products and services.</p>
                            <p>We do not rent or sell your personal information to third parties. We only share your information with the Entities who have registered themselves and have got verified by Adintors. </p>
                            <h4>With whom do we share your information: </h4>
                            <p>We may share your information with Entities and Influencers who have registered themselves on our application to sponsor influencers for content, including brands and agencies, to connect influencers with sponsored content opportunities. We may also share your information with service providers who assist us in providing our services, such as payment processors. </p>
                            <h4>Data Security:</h4>
                            <p>i. We are committed to maintaining the highest standards of data security to protect the sensitive information we handle on behalf of our users. Our data security practices:</p>
                            <ul>
                                <li>Adhere to or exceed industry standards, taking into consideration the sensitivity of the data we manage.</li>
                                <li>Fully comply with all applicable laws, regulations, and guidelines related to data security and privacy.</li>
                                <li>Are meticulously designed to prevent any unauthorized access, destruction, loss, alteration, disclosure, distribution, or compromise of the data we handle, including Platform Data.</li>
                            </ul>
                            <p>ii. We maintain an open channel for individuals to report any security vulnerabilities they may discover within our applications. Any identified deficiencies are promptly addressed to ensure the security of our users' data.</p>
                            <p>iii. We do not engage in the solicitation, collection, storage, caching, proxying, or use of login credentials from any third-party users.</p>
                            <p>iv. User IDs, access tokens, and secret keys are not transferred or shared except when working with authorised Service Providers who assist in building, running, or operating our applications.</p>
                            <p>Technical safeguards include using encryption technology to protect your information during transmission and storage, regularly updating our software and systems to ensure they are secure and protected against vulnerabilities and threats, and using firewalls to prevent unauthorised access to our systems. However, within the chat feature, if another user misuses the uploaded media, Adintors is not held responsible for it.</p>
                            <p>Physical safeguards include limiting access to our facilities and servers to authorised personnel only, implementing strict access controls and identification procedures, and using secure methods of destruction when disposing of any personal information that is no longer needed.</p>
                            <p>Administrative safeguards include providing training and education to our employees and contractors to ensure that they understand and comply with our privacy and security policies, regularly conducting security audits and risk assessments, and conducting background checks on all employees and contractors who have access to sensitive data.</p>
                            <p>Despite these measures, please be aware that no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your personal information.</p>
                            <p>In the event of a data breach, we have procedures in place to respond quickly and effectively to mitigate any harm caused. We will notify affected individuals and any relevant authorities as required by law and take appropriate steps to prevent a recurrence of the breach.</p>
                            <p>We are committed to continually improving our data security measures and regularly review and update our policies and procedures to ensure that we are using the latest technologies and best practices to protect your personal information.</p>
                            <h4>Deletion Policy: </h4>
                            <p>If the user deactivates their account and does not log in for 30 days, we will permanently delete their account. After the permanent deletion of their account, we still retain their email and phone numbers. However, we remove other information such as profile details, bank information, and social media-related data. Chats and transactions will be kept in our database for 365 days of permanent deletion by the user for future security purposes.</p>
                            <h4>Prohibited Policy: </h4>
                            <p>Our platform strictly prohibits the upload of adult content, alcohol-related content, or any materials related to drugs. Any attempts to upload such content will result in permanent account suspension. We maintain a safe and responsible community for all our users by enforcing these content guidelines.</p>
                            <h4>Data Retention: </h4>
                            <p>We retain your personal information for as long as necessary to provide our services to you and for other legitimate business purposes, such as maintaining our records and complying with our legal obligations.</p>
                            <h4>Incident Reporting: </h4>
                            <p>i. If any of the following events occur, you must inform us within 24 hours of becoming aware of them. We'll need details about:</p>
                            <p>Any unauthorized actions involving Platform Data, such as access, destruction, loss, alteration, disclosure, distribution, or compromise, especially if they violate our terms or policies.</p>
                            <p>Any incidents that could potentially harm the security, confidentiality, or integrity of your IT systems or those of your service providers or sub-service providers.</p>
                            <p>ii. You should promptly take steps to address the issue and cooperate with us. This includes sharing information about how the incident affects Platform Data, the actions you're taking to fix it, and keeping us updated on your compliance with any legal requirements related to notifications or other obligations.</p>
                            <h4>Children's Privacy:</h4>
                            <p>Our services are not intended for use by children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us at <a href='mailto:info@adintors.com'>info@adintors.com</a> and we will delete the information.</p>
                            <h4>Your Rights:</h4>
                            <p>You may choose to opt out of receiving promotional communications from us by following the instructions in those communications. You may also update your account information or delete your account at any time. You can do this by contacting us at <a href='mailto:info@adintors.com'>info@adintors.com</a></p>
                            <h4>Changes to this Privacy Policy:</h4>
                            <p>We may update this privacy policy from time to time to reflect changes in our app and services. We will notify you of any changes by posting the updated privacy policy on our website or through the app.</p>
                            <h4>Contact Us:</h4>
                            <p>If you have any questions or concerns about this privacy policy, please contact us at <a href='mailto:info@adintors.com'>info@adintors.com</a></p>


                        </div>

                    </div>




                    {/* <CtaLayoutOne /> */}
                    <FooterOne parentClass="" />

                </main>
            </>
        </>
    )
}

export default PrivacyPolicy;