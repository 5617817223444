import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';
import HeaderOne from '../common/header/HeaderOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const OurProduct = () => {

  // State and handlers for brand slider
  const brandImages = [
    "./images/brand/brand-screen1.svg",
    "./images/brand/brand-screen2.svg",
    "./images/brand/brand-screen3.svg",
    "./images/brand/brand-screen4.svg",

  ];

  const [brandCurrentIndex, setBrandCurrentIndex] = useState(0);

  const handleBrandNext = () => {
    setBrandCurrentIndex((prevIndex) => (prevIndex + 1) % brandImages.length);
  };

  const handleBrandPrevious = () => {
    setBrandCurrentIndex(
      (prevIndex) => (prevIndex - 1 + brandImages.length) % brandImages.length
    );
  };

  // State and handlers for creator slider
  const creatorImages = [
    "./images/brand/creator-screen1.svg",
    "./images/brand/creator-screen2.svg",
    "./images/brand/creator-screen3.svg",
    "./images/brand/creator-screen4.svg",
  ];

  const [creatorCurrentIndex, setCreatorCurrentIndex] = useState(0);

  const handleCreatorNext = () => {
    setCreatorCurrentIndex((prevIndex) => (prevIndex + 1) % creatorImages.length);
  };

  const handleCreatorPrevious = () => {
    setCreatorCurrentIndex(
      (prevIndex) => (prevIndex - 1 + creatorImages.length) % creatorImages.length
    );
  };

  return (
    <>
      <SEO title="Our Product" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />

        <div className='about-us-background'>
          <div className="about-us-container">

            <div className="breadcrum-About">

              <div className="breadcrumb">
                <ul className="list-unstyled">
                  <li>
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li className="active">Our Product</li>
                </ul>
              </div>

            </div>
          </div>

          {/* Brand section */}
          <div
            className="slider-container"
            style={{
              backgroundImage: `url('./images/about/about-B.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="left-panel">
              <h2 className='left-panel-heading'>Discover, Connect, Collaborate</h2>
              <p className='left-panel-desc'>Connect with trusted Brands and get paid for the passion and efforts you have put in your content.</p>
              <div className="button-container-product">
                <button className="left-arrow" onClick={handleBrandPrevious}>
                  <img src="./images/brand/left-icon-btn.svg" alt="Left" />
                </button>
                <button className="right-arrow" onClick={handleBrandNext}>
                  <img src="./images/brand/right-icon-btn.svg" alt="Right" />
                </button>
              </div>
            </div>

            <div className="right-panel">
              <div className="slider-track" style={{ transform: `translateX(-${brandCurrentIndex * 100}%)` }}>
                {brandImages.map((image, index) => (
                  <img key={index} src={image} alt={`Slider ${index}`} className="slider-image" />
                ))}
              </div>
            </div>
          </div>

          {/* Creator section */}
          <div
            className="slider-container"
            style={{
              backgroundImage: `url('./images/about/about-C.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="left-panel">
              <h2 className='left-panel-heading'>Discover, Connect, Collaborate</h2>
              <p className='left-panel-desc'>Connect with genuine Creators who will amplify your Brand's reach with their persistent hard work and powerful influence.              </p>
              <div className="button-container-product">
                <button className="left-arrow" onClick={handleCreatorPrevious}>
                  <img src="./images/brand/left-icon-btn.svg" alt="Left" />
                </button>
                <button className="right-arrow" onClick={handleCreatorNext}>
                  <img src="./images/brand/right-icon-btn.svg" alt="Right" />
                </button>
              </div>
            </div>

            <div className="right-panel">
              <div className="slider-track" style={{ transform: `translateX(-${creatorCurrentIndex * 100}%)` }}>
                {creatorImages.map((image, index) => (
                  <img key={index} src={image} alt={`Slider ${index}`} className="slider-image" />
                ))}
              </div>
            </div>
          </div>

        </div>
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default OurProduct;
