import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
// import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
// import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
// import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import { Link } from 'react-router-dom';




const TermsOfUse = () => {

    return (
        <>
            <>
                <SEO title="About Us" />
                <ColorSwitcher />
                <main className="main-wrapper">
                    <HeaderOne />


                    <div className='about-us-background'>
                        <div className="about-us-container">

                            <div className="breadcrum-About">

                                <div className="breadcrumb">
                                    <ul className="list-unstyled">
                                        <li>
                                            <Link to={"/"}>Home</Link>
                                        </li>
                                        <li className="active">PrivacyPolicy</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className='about-us-data'>
                            <p>Welcome to Adintors Private Limited. These Terms of Service outline the rules and regulations for the use of our services.</p>
                            <p>Please read the Terms of Service carefully before using the platform or registering on the platform or accessing any material or information through the platform. By clicking on the "I Accept" button, you accept these Terms of Services and agree to be legally bound by the same.</p>
                            <p>Use of and access to the platform is offered to you upon the condition of acceptance of all the terms, conditions, and notices contained in this Terms of Service and Privacy Policy, along with any amendments made by Adintors at its sole discretion and posted on the platform from time to time.   </p>
                            <h4>Who are We? </h4>
                            <p>Adintors Private Limited brings you an innovative application called "Adintors". </p>
                            <p>Adintors is an innovative platform that connects Businesses and Brands to Creators and Influencers for effective brand promotion and marketing. We are not a social media company, but a platform that bridges the gap between Businesses and creators to achieve successful brand promotion and marketing. Our company provides a user-friendly application that simplifies the process of finding the right creators for your brand and streamlines the entire collaboration process. </p>
                            <p>Our platform is designed to meet the needs of both Businesses and Influencers. For businesses, we offer a wide range of features to help them identify and collaborate with the right influencers. For influencers, we provide a streamlined way to partner with various brands and monetize their content.</p>
                            <p>Our company is committed to providing exceptional service to our users. We prioritize transparency and communication throughout the entire process, from initial connection to final delivery.</p>
                            <h4>1.	INTRODUCTION</h4>
                            <p>These Terms of Service apply to all the services provided by our company. By using our services, you agree to these Terms of Service. We may modify these Terms of Service at any time without prior notice. You should check these Terms of Service regularly to ensure that you are aware of any changes.</p>
                            <h4>2. DEFINITIONS</h4>
                            <p>“Adintors”, “We”, “us, and “Our” refer to Adintors Private Limited. “Company” refers to any individual or company or brand or individual sponsor that uses our services, and “Creators”, “Influencers” refers to social media influencers and creators who use our services. “Services” refers to the provisions provided based on data about social media handles, niches, and Detailed Audience Demographic Insights. </p>
                            <h4>Part A - General Terms of Service: </h4>
                            <h5>1.	Services: </h5>
                            <p>Adintors is a platform where brands and companies can connect with influencers and creators based on the data about the influencer's social media handles (if connected), country, city, niche, and other data like detailed audience demographic insights. </p>
                            <h5>2.	Company-Influencer Relationship:</h5>
                            <p>Our platform serves as a connection point between Companies and Creators. We do not guarantee any specific results or outcomes from these connections. The terms of any collaboration between Companies and creators are solely between them and do not involve our platform.</p>
                            <h4>Part B - Specific Terms of Services for Entities: </h4>
                            <h5>1.	Registration: </h5>
                            <p>You shall be permitted to access Adintors platform and connect with Influencers and Creators on the platform only upon creating an Account and obtaining a registration on the platform through our application. Your ability to continue using Adintors application is subject to your continued registration on the platform. </p>
                            <p>For registration, you will be required to enter your personal information:</p>
                            <p>For Proprietor/Individual: Entity Name, Entity Type, Country, and Email Address. </p>
                            <p>For Partnership, LLP, Private Limited, Limited: Entity Name, Entity Type, Country, and Email Address. </p>
                            <p>We will collect the above information while registering on Adintors application. </p>
                            <p>To take a verified tag, you will be required to undertake a verification process to verify your personal information and set up the Account. </p>
                            <p>Requirements for Verification tag for Proprietor: Entity Name, Email Address, Phone Number, PAN-Aadhar/GST Number, About Company, Niche, Profile Photo. </p>
                            <p>Requirements for Verification tag for other company types: Entity Name, Email Address, Phone Number, GST Number, About Company, Niche, Profile Photo. </p>
                            <p>We will display only your Company Name, Profile Photo, About Company (provided by you) Insights (if you have connected your Instagram account to Adintors) on your public visible profile. We also have the right to display the feedback provided for you on the application. </p>
                            <p>You agree and accept that as of the date of your registration on the application, the information provided by you is complete, accurate, and up-to-date. In the event of any change to such information, you can edit your information and undergo the same process of verification again. You acknowledge and accept that Adintors has not independently verified the information provided by you. Adintors shall in no way be responsible or liable for the accuracy or completeness of any information provided by you. If you provide any information that is untrue, inaccurate, not current, or incomplete, or Adintors has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, Adintors reserves the right to suspend or terminate Your Account and refuse all current or future use of the platform (or any portion thereof) at any time.</p>
                            <h5>2.	Services: </h5>
                            <p>Adintors application provides you with the following services: </p>
                            <p>Search and Discovery: The platform offers a powerful search engine to find influencers and creators based on relevant criteria such as Audience Country, Audience City, Demographics, Niche, and Follower Count, Creator's Gender, Audience Gender, Audience Age, Creator's Country, Audience Country.  The companies can opt for the best-suited influencer/s based on their specific requirements. You can also chat with the creators(only after they accept your offer) to give them specific requirements for your product's/service's marketing.</p>
                            <p>Campaign Management: The platform provides tools to create, manage, and track influencer marketing campaigns efficiently. Brands can select creators based on their individual budgets. </p>
                            <p>Analytics and Reporting: The platform offers comprehensive analytics and reporting tools that enable brands to track the performance of their campaigns, and get insights into audience engagement. Adintors hold no responsibility for the results provided by the Influencers. </p>
                            <h5>3.	Payment and Contract Management: </h5>
                            <p>On Adintors platform, the Influencer/s have full right to set their own rates for the content they create and the promotions they run. You can find an influencer/s that suits your specific requirements based on your specific budget. </p>
                            <p>There are several factors that can influence an influencer's pricing, including the size of their followers, the engagement rate of their audience, the niche they specialize in, and their level of expertise in their field.</p>
                            <p>For example, an influencer with a large following of highly engaged fans in a specific niche, will likely be able to command a higher price for sponsored content or brand partnerships than an influencer with a smaller following in a less popular niche. In addition, creators can set their own rates based on the type of content they are creating or promoting. </p>
                            <p>The platform provides a secure payment gateway. After the campaign is created by the company and accepted by the creator, the company needs to pay the discussed amount with the creator at Adintors platform. When the payment is done, the campaign will proceed further. Once the creator completes the campaign, they will notify on the platform within 24 hours. Adintors will pay the influencer for the campaign they have completed within 15 working days. </p>
                            <p>You hereby agree and acknowledge that Adintors is only a facilitator between you and the Influencer/s. Adintors only provides you with access to the platform to connect with Influencers/s for you to initiate transactions on the platform. The results of the campaign are not the responsibility of Adintors, however, if an Influencer doesn't start a campaign or post the requested, you can raise a dispute or approach customer care. If Adintors find your dispute true and genuine, the required service/refund will be provided. </p>
                            <h5>4.	Authenticity and Transparency: </h5>
                            <p>The Influencer will always disclose any sponsored content or affiliate links to their audience, and will clearly label all content as such. The Influencer will not mislead their audience or make false claims about any products or services.</p>
                            <h5>5.	Creative Freedom: </h5>
                            <p>The Influencer will have creative control over the content that they produce and will work with the sponsoring company to ensure that the content aligns with their brand values and messaging. However, the Influencer reserves the right to decline any content that they do not feel comfortable producing or that goes against their personal values.</p>
                            <h5>6.	Timelines: </h5>
                            <p>The company will provide an estimated timeline for when they want the content to be produced and published. They will strive to meet all agreed-upon deadlines and will communicate any changes or delays as soon as possible. However, if the sponsoring company's campaign was not initiated by the Influencer, it has the right to terminate the campaign immediately with a full refund and find a new influencer.  </p>
                            <h5>7.	Rating: </h5>
                            <p>After completion of a campaign on the platform,  the platform will prompt the company with an option to provide feedback about the Influencer concerning the services performed by the Influencer. </p>
                            <p>Adintors has the right to display such feedback on its platform, without attribution to or approval of Users and You hereby consent to the same. Adintors reserves the right to remove feedback if such feedback includes obscenities or other objectionable content, including an individual’s name or other personal information, or violates any privacy laws, other applicable laws, or Adintors or its user's content policies.</p>
                            <h4>Part - C Specific Terms of Service for Influencers and Creators: </h4>
                            <h5>1.	Registration: </h5>
                            <p>You shall be permitted to access Adintors platform and connect with sponsored companies on the platform only upon creating an Account and getting verified by Adintors. Your ability to continue using Adintors application is subject to your continued registration on the platform. </p>
                            <p>For registration, you will be required to enter Your personal information including your: Full Name, Date of birth, Country, and Email Address, and Connect with social media (Instagram) while registering on Adintors application. </p>
                            <p>To take a verified tag, you will be required to undertake a verification process to verify your personal information and set up the Account.</p>
                            <p>Requirements for Verification tag: Full Name, Email Address, Phone Number, Bio, Profile Photo, Date of Birth, Age (needs to be 13+), Niche, Gender, Instagram account (if connected), Pricing, Aadhar-PAN-Enrolment ID or GST, and Bank Details. </p>
                            <p>We will display only your Full Name, Profile Photo, Description, Niche/s, Services Provided, Service Charges, and collaborations (if connected with your Instagram account) on your profile. We also have the right to display the feedback provided for you on the application. We also have the right to show data about your social media handles (if connected) niche, performance metrics such as engagement, and, Detailed Demographic Insights that help companies select the best creators for their campaigns. You agree and accept that as of the date of your registration on the application, the information provided by you is complete, accurate, and up-to-date. You acknowledge and accept that Adintors has not independently verified the information provided by you. Adintors shall in no way be responsible or liable for the accuracy or completeness of any information provided by you. If you provide any information that is untrue, inaccurate, not current, or incomplete, or Adintors has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, Adintors reserves the right to suspend or terminate Your Account and refuse any and all current or future use of the platform (or any portion thereof) at any time.</p>
                            <h5>2.	Services: </h5>
                            <p>Profile and Portfolio Creation: Adintors provides a platform where influencers and creators can create their profiles to showcase their skills, niches, and experience. Creators have full provision to showcase their portfolio of previous work on various social media platforms to attract more brands.</p>
                            <p>Campaign Management: The platform provides tools to manage and track influencer marketing campaigns easily. Creators can accept or reject campaign offers, and track their progress.</p>
                            <p>Community and Networking: The platform also provides networking opportunities and exposure to new brands and campaigns.</p>
                            <p>Security: You have the immediate right to complain against any fraud, obscene, immoral requests, or demands for marketing campaigns. Adintors will take immediate action against such complaints and if required, even terminate such accounts. </p>
                            <h5>3.	Payment and Contract Management:</h5>
                            <p>On Adintors platform, the Influencer/s have full right to set their own prices for the content they create and the promotions they run. The companies can find an influencer/s that suits their specific requirements based on their specific budget.</p>
                            <p>The platform provides a secure payment gateway between creators and brands. Adintors act as a bridge between the companies and creators to ensure that no hard work of the creator/s goes in vain. Once the campaign deal is finalized between the company and the Influencer, the company needs to pay to Adintors for the campaign. Only then, the campaign can proceed further. After the campaign is fully completed by the Creator, the payment will be processed further to the creator within 15 working days. </p>
                            <h5>4.	Authenticity and Transparency: </h5>
                            <p>The Influencer will always need to disclose any sponsored content or affiliate links to their audience and will need to clearly label all content as such. The Influencer should never mislead their audience or make false claims about any products or services.</p>
                            <h5>5.	Creative Freedom: </h5>
                            <p>The Influencer will have creative control over the content that they produce and will need to work with the sponsoring company to ensure that the content aligns with their brand values and messaging. However, the Influencer reserves the right to decline any content that they do not feel comfortable producing or that goes against their personal values.</p>
                            <h5>6.	Timelines: </h5>
                            <p>The company will provide an estimated timeline for when they want the content to be produced and published. The Influencer must strive to meet all agreed-upon deadlines and must communicate any changes or delays as soon as possible. The Influencer should keep in mind that if the sponsoring company doesn't get the work on time, they have the right to terminate the campaign immediately and you won't be paid any amount for the terminated campaign. This would also affect your ratings. </p>
                            <h5>7.	Ratings: </h5>
                            <p>After the completion of a transaction on the platform,  the platform will prompt the Sponsoring Company with an option to provide feedback about the Influencer concerning the services performed by the Influencer. Your performance will determine the feedback provided by the sponsored companies you work with. </p>
                            <p>Adintors reserve the right to display such feedback without attribution to or approval of Users and You hereby consent to the same. Adintors reserve the right to edit or remove feedback if such feedback includes obscenities or other objectionable content, including an individual’s name or other personal information, or violates any privacy laws, or other applicable laws, on Adintors or its user's content policies.</p>
                            <h4>Part D: General Terms of Use: </h4>
                            <h5>1.	Eligibility to Use</h5>
                            <p>Adintors platform is not available for children under the age of 13 years or to any Users suspended or removed by Adintors from accessing the platform for any reason whatsoever. You represent that you are of legal age to form a binding contract and are not a person barred from receiving, using or availing Adintors under the applicable laws.</p>
                            <p>Adintors reserves the right to refuse access to the platform, at any time to new Users or to terminate or suspend access granted to existing Users at any time without according to any reasons for doing so.</p>
                            <p>You are prohibited from campaigning, otherwise transferring Your Account to another party, or impersonating any other person to create an account with Adintors. </p>
                            <h5>2.	User Account, Password, and Security</h5>
                            <p>In order to use Adintors application, you will have to register on the application and create an account with a unique user identity ("Account"). If you are using Adintors on a compatible mobile or tablet, you will have to install the application and then proceed with registration.</p>
                            <p>You will be responsible for maintaining the confidentiality of the Account information and are fully responsible for all activities that occur under Your Account. You agree to (i) immediately notify Adintors of any unauthorized use of Your Account information or any other breach of security, and (ii) [ensure that You exit from Your Account at the end of each session.] Adintors cannot and will not be liable for any loss or damage arising from your failure to comply with this provision. You may be held liable for losses incurred by Adintors or any other User of or visitor to the application due to authorised or unauthorised use of your Account as a result of your failure to keep Your Account information secure and confidential. Use of another User’s Account information for using the Platform is prohibited.</p>
                            <h5>3.	Confidentiality </h5>
                            <p>We will maintain the confidentiality of all information provided by the companies and the influencers. </p>
                            <h5>4.	Intellectual Property </h5>
                            <p>All intellectual property rights in the data and information provided by the Company and the Influencer remain with their respective owners. Companies are granted a limited, non-exclusive, non-transferable licence to use the data and information provided solely for their promotional campaigns.</p>
                            <h5>5.	Limitation of Liability </h5>
                            <p>Adintors is not responsible for any loss or damages incurred by the company as a result of their use of the services provided by influencers. Adintors are not liable for any damages resulting from the actions or omissions of the creator engaged by the company, and the company indemnifies Adintors against any claims arising from such actions or omissions. Adintors reserve the right to remove any content that violates intellectual property rights.</p>
                            <h5>6.	Termination </h5>
                            <p>Adintors reserves the right to terminate a company’s or influencer's access to our platform at any time for any reason. Termination may result in the deletion of all data associated with the account.</p>
                            <h5>7.	Report Abuse</h5>
                            <p>In the event, you come across any abuse or violation of these Terms of Service or if you become aware of any objectionable content on Adintors, please report the same to the following e-mail id:  <a href='mailto:info@adintors.com'>info@adintors.com</a></p>
                            <p>In case You have any queries concerning the Terms or Adintors, please write to Us at <a href='mailto:info@adintors.com'>info@adintors.com</a></p>
                            <h5>8.	Complaints</h5>
                            <p>In case of any dissatisfaction concerning the Platform, you shall first file a formal complaint with the customer service of Adintors, before pursuing any other recourse. The complaints can be lodged at <a href='mailto:info@adintors.com'>info@adintors.com</a>  and upon lodging a complaint you agree to provide complete support to the customer service team with such reasonable information as may be sought by them from you. The decision of the Company on the complaints shall be final and you agree to be bound by the same.</p>



                        </div>

                    </div>




                    {/* <CtaLayoutOne /> */}
                    <FooterOne parentClass="" />

                </main>
            </>
        </>
    )
}

export default TermsOfUse;