import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BlogListOne from '../../component/blog/BlogListOne';


const BlogOne = () => {
    return (
    <>
        {/* // <div className="section section-padding-equal"> */}
            {/* <div className="container"> */}
            <div className='blog-section'>
                <SectionTitle className={'blog-section-content'}
                    // subtitle="What's Going On"
                    title="Blogs"
                    description=""
                    textAlignment=""
                    textColor=""
                />
                {/* <div className="row g-0"> */}
                    
                    <BlogListOne colSize="col-xl-12" itemShow="2" />

                    </div>
                  
                {/* </div> */}
            {/* </div> */}
            <ul className="shape-group-1 list-unstyled">
                <li className="shape shape-1"><img src={ "/images/others/bubble-1.png"} alt="bubble" /></li>
                {/* <li className="shape shape-2"><img src={ "/images/others/line-1.png"} alt="bubble" /></li> */}
                {/* <li className="shape shape-3"><img src={ "/images/others/line-9.png"} alt="bubble" /></li> */}
                {/* <li className="shape shape-4"><img src={ "/images/others/bubble-2.png"} alt="bubble" /></li> */}
            </ul>
     {/* </div> */}
        </>
    )
}

export default BlogOne;