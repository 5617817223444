import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alert from "react-bootstrap/Alert";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      Your Message has been successfully sent.
    </Alert>
  );
};

const FormOne = () => {
  const form = useRef();

  const [result, showresult] = useState(false);
  const[formDetails , setFormDetails] = useState({
    name:'',
    email:'',
    phone:'',
    tech:''
  })

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_ol1glnk",
  //       "template_pnfviu9",
  //       form.current,
  //       "ojrbErZOHf3uNLero"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   form.current.reset();
  //   showresult(true);
  // };

  // setTimeout(() => {
  //   showresult(false);
  // }, 5000);
  function sendEmail(e) {
    // e.preventDefault()
const formEle = document.querySelector("form");
const formDatab = new FormData(formEle);
fetch(
  "https://script.google.com/macros/s/AKfycbxLt9hwWNPHPQaQx3e2JafSgR1fySbYjdNcbLBJX0a7VtW_tecjFAcTpUYafrfDLKzAew/exec",
  {
    method: "POST",
    body: formDatab
  }
)
  .then((res) => res.json())
  .then((data) => {
    console.log(data);
    
  })
  
  .catch((error) => {
    console.log(error);
  });
  }
  return (
    <form ref={form} onSubmit={(e) => sendEmail(e)} className="axil-contact-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="Name"
          placeholder="Enter Name"
          value={formDetails.name}
          onChange={(e) => setFormDetails({...formDetails , name: e.target.value})}
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="Email"
          placeholder="example@mail.com"
          value={formDetails.email}
          onChange={(e) => setFormDetails({...formDetails , email:e.target.value})}
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="Phone"
          placeholder="phone"
          value={formDetails.phone}
          onChange={(e) => setFormDetails({...formDetails , phone:e.target.value})}
          required
        />
      </div>

      <div className="form-group mb--40">
        <label>Select Technology</label>
        <select  name="Technology" className="form-control" value={formDetails.tech}  onChange={(e) => setFormDetails({...formDetails , tech:e.target.value})} required>
          <option>Select</option>
          <option value="Flutter developers">Flutter developers</option>
          <option value="Graphic Designer">Graphic Designer</option>
          <option value="MERN Full Stack Developer">MERN Full Stack Developer</option>
          <option value="Quality Analyst">Quality Analyst</option>
          <option value="UI/UX Product Designer">UI/UX Product Designer</option>
          
        </select>
      </div>
      {/* <div className="form-group mb--40">
        <label>Resume:</label>
        <input  type="file" required className="form-control" name="resume" />
      </div> */}
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Submit
        </button>
      </div>
      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
};

export default FormOne;
